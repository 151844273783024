html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mouse-user * {
  outline: none;
}

html,
body {
	font-size: 100%;
	margin: 0;
	overflow: visible !important;
	background: $website-bg-color;
	height: 100%;
	@if $enable-font-smoothing == true {
		font-smooth: always;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
	}
}
html{
	overflow: hidden;
	-webkit-tap-highlight-color: rgba(252, 214, 172, 1);
	line-height: 1em;
	&[dir="rtl"] { direction: rtl; }
}

body{
	-webkit-font-smoothing: antialiased;
	font-family: Arial, Helvetica, sans-serif;
	overflow: scroll;
	overflow-y: scroll !important;
	min-width: $min-width;
	font-family: $font-family;
	font-size: $font-size-em;
	font-weight: $font-weight;
	line-height: $line-height;
	-webkit-overflow-scrolling: touch;
  color: $dark-blue;
}

.container{
	@include container;

  &_narrow{
    @extend .container;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(large) {
      padding-left: $container-spacing-narrow;
      padding-right: $container-spacing-narrow;
    }
  }
}

.clearfix {
	@extend %clear;
}

.block {
    overflow: hidden;
	width: 100%;
	margin: 0 0 $spacing-large;

	@include breakpoint(medium) {
		margin: 0 0 $spacing-xxlarge;
	}
}

.page {
	&__root {
		width: 100%;
		display: table;
		height: 100vh;
		table-layout: fixed;

		&.on-page-editing {
			display: block;
			max-height: 100vh;
			height: auto;
			overflow-y: auto;
		}
	}

	&__breadcrumbs {
		@media print {
			display: none;
		}
	}

  &__header {
    width: 100%;
    top: 0;
    z-index: 10;
    color: $dark-blue;

    &_on-dark-bg {
      position: absolute;
      color: $white;

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(0,0,0,0.3) 100%);
        top: 0;
        left: 0;
      }
    }
  }

  &__heading {
    @include font-size(36px);
    font-weight: 600;
    margin-top: 27px;
    margin-bottom: 15px;
    @include breakpoint(medium) {
      @include font-size(53px);
      margin-top: 65px;
      margin-bottom: 20px;
      padding-right: calc(#{$spacing-large} + 220px);
    }
  }

  &__row {
    display: flex;
  }

  &__body {
    padding-top: 45px;
    font-weight: 400;
    @extend .container_narrow;
  }

  &__media {
    padding-top: 45px;
  }

	&__content {
		position: relative;
	}

  &__container {
    @extend .container;
  }

	&__footer{
		display: table-row;
		height: 1px;
		position: relative;
		z-index: 10;

		@media print {
			display: none;
		}
	}
}

/*** SKIP TO MAIN CONTENT ***/
#skip-to-main {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background: #fff;
	display: flex;
	padding: 20px;
	color: #000;
	font-weight: bold;
	transition: transform 100ms ease-in-out, opacity 100ms linear;
	transform: translateY(-100%);

	&:focus {
		transform: none;
	}

	@media print {
		display: none;
	}
}

.todo{
	position: relative;
	overflow: hidden;
	border: 1px solid red;
	padding: 0px;


	&:before{
		content: attr(data-todo-text);
		float: right;
		background-position: 0 0;
		background-repeat: no-repeat;
		background-color: red;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAOCAMAAABuIH5JAAAAMFBMVEX///////////////////////////////////////////////////////////////9Or7hAAAAAD3RSTlMAESIzRFVmd4iZqrvM3e5GKvWZAAAAuUlEQVR4AbWRy47FMAhDyePekEfw///tQF1VqWY1i/Gisg7GqIr8kzYoE2nhR3ZD1HycNJxKqKkubFWV6Tk1WJERYEUi+bZ2YCUWD6zYiZqY7RssmDtUkQr0Mzo9w41K8HVnGHLN7IySRsOHwN11ifvpd7T4lKC8ouWIbkzhWba6e6L93dq5OWDpAXnzB7aDI5oNK4sCSuClKz5T0vRLd5SnP4ZQD0DV5zW+QmUtNE1V8wVCjTCs/E0/qlsMpWWH2kkAAAAASUVORK5CYII=);
		position: relative;
		z-index: 101;
		min-height: 16px;
		color: #fff;
		padding-left: 46px;
		font-size: 14px;
		line-height: 14px;
	}

	&:after{
		display: block;
		content: " ";
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: rgba( 255, 0, 0, 0.3);
	}
}
