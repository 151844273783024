.ingredients {
  &__heading {
    @include font-size(26px);
    line-height: 1.25;
    margin-top: 67px;

    @include breakpoint(medium) {
      @include font-size(32px);
      margin-top: 26px;
    }

    @media print {
      margin-top: 30px;
      margin-bottom: 10px;
      @include font-size(25px);
      line-height: normal;
    }
  }

  @media print {
    .drop-down {
      margin-bottom: 10px;
      &__label {
        padding-left: 0;
        @include font-size(23px);
        line-height: normal;
      }
      &__header {
        padding: 6px;
        font-weight: 500;
        border: none;
        min-width: 0;
        padding-left: 0;
  
        &:before, &:after {
          content: none;
        }
      }
    }
    
  }
}
