.social-link {
  &__link {
      @extend .link;
      height: 40px;
      display: flex;
      margin: 15px;
  }

  &__icon {
      fill: white;
  }
}
