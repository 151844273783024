.category-filter { 
    position: relative;
    width: 100%;
    padding: 0;

    $self: &;
   
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  
    &--active {
      z-index: 2;
    }

   &__select {
    // NOTE: Makes the select element overlap the entire fake select to be accessible with touch screens
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
   height: 100%;
    font-size: 16px; // NOTE: Prevent iPhone zoom on focus
    animation: fade-in 0s 1s both; // NOTE: Prevents flash of native select element before mount
  }

  &--is-mounted {
    // NOTE: Makes real select invisible and puts it behind fake select
    select {
      z-index: 0;
      opacity: 0;
      animation-fill-mode: none;
    }
  }

  &--has-touch {
    // NOTE: Puts the invisible real select on top of the fake select to bring up the native select menu on touch
    select {
      z-index: 2;
    }
  }

  &--is-disabled {
    opacity: 0.5;
  }

  &__fake,
  &__element {
    color: $dark-blue;
    font-weight: 500;
  }

  &__fake {
    position: relative;
    user-select: none;

    // NOTE: Outline for keyboard users
    .select:not(.select--has-touch) select:focus + & {
      outline: 1px dotted currentColor;
      outline: 4px solid black;
    }
  }

  &__element {
    background-color: white;
    padding: 14px 15px;
    border: 2px solid $dark-blue;
  }

  &__dropdown {
    list-style: none;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    background-color: white;
    border-top: none;
    border: 2px solid $dark-blue;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    max-height: 200px;
    overflow: auto;
  }

  &__option {
    cursor: pointer;
    padding: 15px;

    &:hover,
    &:focus {
      background-color: $light-blue;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(90deg);

    &--active {
      transform: translateY(-50%) rotate(-90deg);
    }
  }


  #{$self}__select:focus + #{$self}__fake #{$self}__element{
    background: $light-blue;
    
    .mouse-user *{
      background: none;
    }
  }
} 
