@use "sass:math";

$teaser-desktop-spacing: math.div(120px, 3);
$teaser-mobile-spacing: 26px;
$teaser-desktop-width: calc(100% / 3 - 20px);
$teaser-mobile-width: calc((100% - #{$teaser-mobile-spacing}) / 2);

.teasers-list {
  $self: &;
  clear: both;
  margin-bottom: 50px;

  @include breakpoint(medium) {
    margin-bottom: 95px;
    margin-top: 95px;
  }

  &__heading {
    @include font-size(32px);
    color: $dark-blue;
  }

  &__items {
    list-style: none;
    margin-top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      margin-bottom: 40px;
      flex-wrap: nowrap;
    }
  }

  &__item {
    width: $teaser-mobile-width;
    margin-left: $teaser-mobile-spacing;

    @include breakpoint(extra-small-max, $min-max: "max") {
      width: 100%;
      margin-left: 0;
    }

    @include breakpoint(small-max, $min-max: "max") {
      margin-bottom: $teaser-mobile-spacing;

      &:nth-child(odd) {
        margin-left: 0;
      }
    }

    @include breakpoint(medium) {
      width: $teaser-desktop-width;
      margin-left: $teaser-desktop-spacing;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  // TEASER
  &__teaser {
    text-decoration: unset;

    &:focus,
    &:hover {
      .image__img {
        transform: scale(1.1);
      }
    }

    &-image {
      overflow: hidden;
      @include fixed-proportion-image(400, 225);
    }

    &-tags {
      @include font-size(16px);
      color: $orange;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 15px;
    }

    &-heading {
      @include font-size(24px);
      color: $dark-blue;
      font-weight: 500;
      margin: 10px 0 8px;
      line-height: 1.2;
    }

    &-details {
      @include font-size(14px);
      color: $dark-blue;
      display: flex;
    }

    &-detail {
      display: flex;
      width: 100%;
      align-items: center;

      &-text {
        padding-left: 10px;
      }
    }
  }

  &__more-link {
    @include font-size(18px);
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    color: $dark-blue;
    text-transform: uppercase;
    padding: 16px 20px;
    border: 2px solid $dark-blue;
    text-decoration: none;
    font-weight: 500;
    width: $teaser-mobile-width;

    @include breakpoint(medium) {
      width: $teaser-desktop-width;
    }

    @include breakpoint(extra-small-max, $min-max: "max") {
      width: 100%;
    }

    &:after {
      display: block;
    }

    &:hover {
      background: $dark-blue;
      color: $white;
      transition: color 0.35s, -webkit-transform 0.35s;
    }
  }
}
