.rich-text {
    color: $dark-blue;

    h2, h3, h4, h5 {
      margin: 0 0 $spacing-medium;
    }

    h2 {
      @include font-size(32px);
      font-weight: normal;
    }

    h3 {
      @include font-size(24px);
    }

    h4 {
      @include font-size(16px);
      font-weight: normal;
    }

    h5 {
      @include font-size(14px);
      font-weight: normal;
    }

    ul, ol {
        padding-left: 50px;
        padding-bottom: $spacing-xlarge;
    }

    li {
      padding-bottom: $spacing-small;
    }

    a {
      color: inherit;
    }

    &_in-getting-started-link-intro, &_in-footer * {
        color: white;
    }

    &_in-full-width-banner {
        color: inherit;
    }

    &_in-footer {
      &.on-editing{
        background: transparent linear-gradient(90deg, $blue 0%, #00558D 100%) 0% 0% no-repeat;
      }

      &.mce-content-body *[data-mce-selected="inline-boundary"]{
        color: black;
        background: white;
      }
        ul {
            list-style: none;
            padding: 0;
        }

        p {
            margin: 0;
        }
    }

  &_in-page-into {
    @include font-size(26px);
    font-weight: 400;
    line-height: normal;
    @include breakpoint(medium) {
      @include font-size(32px);
      line-height: normal;
      padding-right: calc(#{$spacing-large} + 220px);
    }
  }

  &_in-ingredients {
    @media print {
      @include font-size(18px);
      line-height: normal;
    }
    p {
      font-weight: 400;
    }
  }

  &_in-recipe-page-body {
    @media print {
      @include font-size(18px);
      line-height: normal;
    }
    p {
      font-weight: 400;
      @media print {
        margin-bottom: 12px;
      }
    }

    h2 {
      font-weight: 600;
      margin: 25px 0;
      @include breakpoint(medium) {
        @include font-size(30px);
        line-height: normal;
      }
      @media print {
        @include font-size(25px);
        line-height: normal;
      }
    }

    ol, ul {
      padding-left: 20px;
      padding-bottom: 0;
    }

    li {
      padding-left: 5px;
      position: relative;
      margin-bottom: 20px;
      font-weight: 400;

      @media print {
        margin-bottom: 12px;
      }
    }
  }

  &_in-simple-list-teaser,
  &_in-getting-started-teaser {
    font-weight: 400;
    @include breakpoint(medium) {
      @include font-size(24px);
      line-height: normal;
    }
  }

  &_in-top-content {
    @include font-size(26px);
    font-weight: 400;
    line-height: normal;
    @include breakpoint(medium) {
      @include font-size(32px);
      line-height: normal;
    }
    @media print {
      @include font-size(22px);
      line-height: normal;
    }
  }

  &_in-quick-search-panel {
    color: $white;
    @include font-size(20px);
    span {
      border-bottom: solid 2px white;
    }
  }

  &_in-pages-list-item {
    @include font-size(20px);
    font-weight: 400;
    line-height: normal;
  }

  &_in-contact-information {
    font-weight: 400;
    line-height: normal;
    a {
      color: inherit;
      font-weight: 400;
    }
  }

  &_in-image-with-text-block, 
  &_in-factbox-with-text-block {
    @include font-size(20px);
    line-height: normal;
    font-weight: 400;

    h2 {
      font-weight: 600;
      @include breakpoint(medium) {
        @include font-size(34px);
        margin-bottom: 25px;
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      
      @include breakpoint(medium) {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }

  &_in-factbox {
    @include font-size(20px);
    line-height: 1.3;
    font-weight: 400;
    color: $black;
  }
}
