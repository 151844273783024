.contact-us { 
	color: $dark-blue;
	background: $light-blue;
	padding: $spacing-large 0 $spacing-xxlarge;
	margin-bottom: 50px;
	margin-top: 50px;

	@include breakpoint(medium) {
		margin-bottom: 95px;
		margin-top: 95px;
		padding: $spacing-xxlarge 0;
	}

	&__container {
		display: flex;
		justify-content: center;
		@extend .container;
	}

	&__wrapper {
		max-width: 840px;
		width: 100%;
	}

	&__heading {
		font-weight: 400;
		margin-top: 0;
		@include font-size(28px);
		margin-bottom: 35px;

		@include breakpoint(medium) {
		  @include font-size(32px);
		  margin-bottom: 60px;
		}
	}

	.contact {
		&:not(:last-child) {
			margin-bottom: 55px;
		}
	}
}