$simple-teaser-desktop-spacing: 80px * 0.5;
$simple-teaser-mobile-spacing: 20px;
$simple-teaser-desktop-width: calc(100% / 2 - 20px);
$simple-teaser-mobile-width: calc((100% - #{$simple-teaser-mobile-spacing}));

.simple-list {
  margin-top: 50px;

  &__items {
    margin-top: 40px;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    list-style: none;
    width: $simple-teaser-mobile-width;
    margin-left: $simple-teaser-mobile-spacing;

    &:first-child {
      margin-left: 0;
    }

    @include breakpoint(extra-small-max, $min-max: "max") {
      width: 100%;
      margin-left: 0;
    }

    @include breakpoint(small-max, $min-max: "max") {
      margin-bottom: 40px;
    }

    @include breakpoint(medium) {
      width: $simple-teaser-desktop-width;
      margin-left: $simple-teaser-desktop-spacing;
    }
  }
}

.simple-list-teaser {
  $self: &;
  text-decoration: none;
  color: $dark-blue;

  &__image {
    #{$self}:focus &,
    #{$self}:hover & {
      .image__img {
        transform: scale(1.1);
      }
    }
  }

  &__heading {
    margin: 0;

    @include font-size(24px);
    @include breakpoint(medium) {
      @include font-size(32px);
    }
  }

  &__more-link {
    @include font-size(18px);
    display: inline-flex;
    color: $dark-blue;
    text-transform: uppercase;
    padding: 16px 45px;
    border: 2px solid $dark-blue;
    text-decoration: none;
    font-weight: 600;

    #{$self}:focus &,
    #{$self}:hover & {
      background: $dark-blue;
      color: $white;
      transition: all 0.35s, -webkit-transform 0.35s;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 40px;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    &_small {
      flex-direction: column;
    }
  }

  &__column {
    margin-bottom: 30px;
    &_default {
      max-width: 840px;
    }
  }
}
