.breadcrumbs {
  color: $dark-blue;
  @extend .container;
  padding-top: 20px;

  &__items {
    display: flex;
    list-style: none;
  }

  &__item {
    $self: &;
    font-weight: 400;

    @include font-size(12px);
    @include breakpoint(medium) {
      @include font-size(16px);
    }
  }
}
