.link {
  color: inherit;
  font-size: inherit;

  &:hover,
  &_active {
    color: $blue;
    text-decoration: none;
    transition: color 0.35s, -webkit-transform 0.35s;
  }

  &_theme_getting-started {
    border-bottom: solid 2px $white;
    &:hover {
      border-bottom: solid 2px transparent;
    }
  }
}
