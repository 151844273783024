$error-border-color: #FD7777;

.embeddable-form-block {
    $self: &;
    padding-top: 45px;
    clear: both;

    &__content {
        max-width: 840px;
        margin: 0 auto;
    }

    &__fieldset {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border: none;
    }

    &__description {
        display: block;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 12px;
    }

    &__field-label {
        display: flex !important;
        flex-wrap: nowrap;
        &.required {
            &::after {
                content: "*";
                margin-left: 3px;
                display: inline-block;
            }
        }

        &-text {
            @include truncate-text;
            width: auto;
        }
    }

    &__legend {
        @include visually-hidden;
    }

    &__hint {
        margin-top: 15px;
    }

    h2 {
        text-align: center;
        margin: 0 0 50px;
        flex: 0 0 100%;
        
        @include breakpoint(small){
            padding-right: 40px;
        }
    }
    h3{
        margin: 0 0 20px;

        @include breakpoint(small){
            padding-right: 40px;
        }
    }

    a{
        color: $dark-blue;
    }

    &__checkboxes,
    &__full-width-container {
        width: 100% !important;
        margin-right: 0 !important;
        
    }

    #{$self}__full-width-container {
        clear: both;
        
        @include breakpoint(small){
            padding-right: 40px;
        }
    }

    form {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(small){
            width: calc(100% + 40px);
            margin-right: -40px;
        }

        @include placeholder {
            font-family: $font-family;
            color: $dark-blue;
            font-weight: 500;
        }

        & > div, & > fieldset > div {
            width: 100%;
            margin-bottom: 30px;

            @include breakpoint(small){
                width: calc(50% - 40px);
                margin-right: 40px;
            }
        }
        .form-select{
            clear: both;
            position: relative;
            &:after{
                content: '';
                display: block;
                right: 15px;
                border-right: 4px solid $dark-blue;
                border-bottom: 4px solid $dark-blue;
                position: absolute;
                top: 15px;
                box-sizing: border-box;
                transform: rotate(45deg);
                width: 20px;
                height: 20px;
            }

            select{
                position: relative;
                z-index: 2;
                background: transparent;
                @include text-input;
                padding-right: 40px;
                height: 60px;
                -webkit-appearance: none;
                -moz-appearance: none;

                &::-ms-expand {
                    display: none;
                }

                &.error {
                    border-color: $error-border-color;
                }
            }
        }

        textarea,
        input[type="text"],
        input[type="email"],
        input[type="number"],
        input[type="password"] {
            @include text-input;
            width: 100% !important;

            &.error {
                border-color: $error-border-color;

                & + label.error {
                    @include clear-visibility-hidden;
                }
            }
        }
        label {
            display: block;
            font-weight: 500;
            margin: 0 0 6px;

            &.error {
                margin: 2px 0 0;
                color: red;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
            }
        }
        input[type="checkbox"] + label {
            @include clear-visibility-hidden;
            display: inline;
        }

        button {
            width: 100%;
            max-width: 400px;
            display: block;
            margin: 20px 0;
            @include horizontal-align;
            background: $dark-blue;
            border: none;
            color: $white;
            text-align: center;
            padding: 20px;
            line-height: 20px;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
            clear: both;

            &:hover,
            &:focus{
                background-color: $blue;
            }

            @include breakpoint(small){
                margin-left: -20px;
            }
        }

        #{$self}__checkboxes,
        #{$self}__radiobuttons {
            clear: both;
            width: 100%;
            padding-bottom: 0;
            
            @include breakpoint(small){
                padding-right: 40px;
            }

            & + div{
                clear: both;
                @include cf;

                a{
                margin-top: -40px;
                position: relative;
                float: left;
             }
            }

            label {
                &,
                &:not(.error) {
                    @include clear-visibility-hidden;
                    position: relative !important;
                    bottom: auto;
                    left: 0;
                    clear: both;
                    white-space: normal !important;
                    display: block;
                    overflow: hidden;
                    line-height: 25px !important;
                    padding-left: 36px;
                    position: relative;
                    display: block;
                    text-align: left;
                    word-wrap: break-word;
                    text-transform: none !important;
                    min-height: 30px;
                    margin-bottom: 5px;
                }
            }

            input[type="checkbox"] {
                @include visually-hidden;

                &:focus + label{
                    text-decoration: underline;
                    .mouse-user &{
                        text-decoration: none;
                    }
                }

                & ~ label:not(.error) {
                    &:before {
                        content: "";
                        background-color: $white;
                        border: 2px solid $dark-blue;
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        color: $orange;
                        line-height: 22px !important;
                        font-size: 18px;
                        text-align: center;
                    }
                }

                &:checked ~ label:not(.error) {
                    &:after{
                        position: absolute;
                        content: "";
                        left: 8px;
                        top: 2px;
                        width: 10px;
                        height: 16px;
                        border: solid $dark-blue;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
}
