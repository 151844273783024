$block-offset: 110px;

@mixin align-width-to-container() {
	&_aligned {
		margin-left: 0!important;
		margin-right: 0!important;
	}
}

@mixin mixed-content-block() {
	color: $dark-blue;
	@include container($max-width-extra-narrow);
	@include reset-container-inside-content-area;
	@include reset-container-inside-rich-text;

	& {
		box-sizing: content-box;
		margin-bottom: $block-indent-small;
		margin-top: $block-indent-small;
	}

	@include breakpoint(medium) {
		margin-bottom: 80px;
		margin-top: 80px;
	}
}

@mixin mixed-content-container() {
	@include breakpoint(medium) {
		display: flex;
		align-items: center;
	}

	@include breakpoint(large) {
		margin-left: -#{$block-offset};
	}

	@include align-width-to-container;
}

@mixin mixed-content-container-reversed() {
	@include breakpoint(medium) {
		display: flex;
		flex-direction: row-reverse;
	}

	@include breakpoint(large) {
		margin-left: 0;
		margin-right: -#{$block-offset};
	} 
	
	@include align-width-to-container;
}

@mixin mixed-content-container-col($reversed: false, $main-column-width: 50%) {
	width: 100%;
	flex: 1;

	&:not(:last-child) {
		margin-bottom: 25px;
	}

	@include breakpoint(medium) {
		&:not(:last-child) {
			flex: 0 0 #{$main-column-width};
			margin-bottom: 0;
		}

		@if $reversed == true {
			&:not(:first-child) {
				padding-right: 40px;
			}
			&:first-child {
				padding-right: 0;
			}
		} @else {
			&:not(:last-child) {
				padding-right: 40px;
			}
		}

	}
}