a{
	text-decoration: underline;
	color: $font-color;

	&:hover{
        text-decoration: none;
	}
}

p{
	margin: 0 0 1.7em;
	font-size: inherit;
	line-height: inherit;

	&:last-child{
		margin:0;
	}
}

img{
	max-width: 100%;
	display: block;
}

strong,
b{
	font-weight: 700;
}

em,
i{
	font-style: italic;
}

ul,
ol{
	padding: 0;
	margin: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	margin: $spacing-large 0;
}

td,th{
	vertical-align: top;
	border: 1px solid $light-grey;
	padding: $spacing-medium;
}



mark{
	background-color: $selection-background;
}