.recipe-page {
  @extend .page__container;

  @media print {
    padding-bottom: 25px;

    .teasers-list {
      display: none;
    }
  }

  &__heading {
    @extend .page__heading;
    padding: 0;

    @media print {
      font-size: 32px;
    }
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    @include breakpoint(medium) {
      flex-direction: row;
    }
    @media print {
      margin-bottom: 20px;
    }
  }

  &__details {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium) {
      flex-direction: column;
      justify-content: flex-end;
      min-width: 440px;
      padding-left: 150px;
      min-height: 100%;
    }
    @media print {
      justify-content: flex-start;
    }
  }

  &__detail {
    display: flex;
    align-items: center;
    padding-top: 20px;
    flex: 1;
    @include breakpoint(medium) {
      flex: 0 0 auto;
    }
    @media print {
      flex: none;
      padding-right: 20px;
    }
  }

  &__detail-text {
    padding-left: 17px;
  }

  &__media {
    &_mobile {
      margin-bottom: 18px;
      @include breakpoint(medium) {
        display: none;
      }
    }
    &_desktop {
      display: none;
      padding-top: 45px;
      @include breakpoint(medium) {
        display: block;
      }
    }
    @media print {
      display: none;
    }
  }

  &__content-group {
    display: flex;
    flex-direction: column;
    @include breakpoint(medium) {
      flex-direction: row;
    }
    @media print {
      flex-direction: row;
    }
  }

  &__ingredients {
    min-width: 400px;
    padding-right: 40px;

    @media print {
      min-width: 250px;
    }
  }

  &__print-button {
    margin-top: 40px;
    display: none;

    @include breakpoint(medium) {
      display: flex;
    }
  }
}
