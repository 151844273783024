.questionnaire {
    font-weight: 400;
    @extend .page__body;

    &__questions, &__answers {
        list-style: none;
    }

    &__question {
        margin-bottom: $spacing-large;
        @include breakpoint(small){
            max-width: 75%;
        }
        
    }

    &__answers {
        margin-top: $spacing-medium;
    }

    &__button {
        width: 100%;
        appearance: none;
        border: none;
        font-family: inherit;
        background-color: white;
        border: 2px solid $dark-blue;
        color: $dark-blue;
        @include font-size(20px);
        font-weight: 500;
        text-transform: uppercase;
        padding: 20px 15px 15px;
        &:hover {
            color: white;
            background-color: $dark-blue;
        }
    }

    &__answer-text {
        @include font-size(20px);
        &--is-wrong {
            color: #CB2121;
        }
        &--is-correct {
            color: #008B0F;  
        }
    }

    &__question-validation-text{
        color: #CB2121;
    }

    &__answer-label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover input ~ .questionnaire__answer-checkmark {
            background-color: white;
            border: 2px solid $dark-blue;
        }

        input:checked ~ .questionnaire__answer-checkmark {
            background-color:white;
           
            border: 2px solid $dark-blue;
        }

        input:checked ~ .questionnaire__answer-checkmark:after {
            display: block;
        }


        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

    }
  
    &__answer-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color:white;
        border: 2px solid $dark-blue;

        &--radio {
            border-radius: 50%;
            &:after {
                left: 3px !important ;
                top: 3px !important;
                width: 10px !important;
                height: 10px !important;
                border: none !important;
                border-width: none !important;
                background-color: $dark-blue;
                border-radius: 50%;
            }
           
        }

        &:after {
            content: "";
            position: absolute;
            display: none;
        }
        &:after {
            left: 5px;
            top: 2px;
            width: 6px;
            height: 10px;
            border: solid $dark-blue;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);

          
        }
        input:checked ~ .questionnaire__answer-checkmark:after {
            display: block;
          }
    }


} 