//------------------------------------
//  BASE
//------------------------------------
@use "sass:math";

$website-bg-color: #fff !default;


// Enable font smoothing
$enable-font-smoothing: true !default;


// Base font sizes and colors

$tera-size: 53px !default;
$giga-size: 28px !default;
$extra-large-size: 20px !default;
$large-size: 18px !default;
$medium-size: 16px !default;
$small-size: 14px !default;
$extra-small-size: 12px !default;



//------------------------------------
//  COLORS
//------------------------------------



$white: white;
$black: black;
$grey: #cdd1d7;
$light-grey: #E6E9EB;
$maincolor: #6a6d6f;
$orange: #F05B13;


$light-blue: #ddeefc;
$dark-grey: #343638;
$dark-blue: #00568A;
$extra-dark-blue: #003152;
$blue: #008FBE;
$secondary-color: $dark-blue;

//-----------------------------------------------------
// Calculation variables
//-----------------------------------------------------
$base-font-size: 16px !default; // WARN! Never change - it is a constans needed for calculations.
$base-line-height: $extra-large-size !default;
$line-height-ratio: math.div($base-line-height, $base-font-size);
//-------------------------------------------------------

$font-family: 'Radikal', Arial, sans-serif;
$font-family-secondary: Helvetica, sans-serif;
$font-size-em : #{math.div($extra-large-size, $base-font-size) * 1em};
$font-weight: 300;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold:700;
$print-font-family: Georgia, "Times New Roman", Times, serif !default;

$line-height: $giga-size;

$font-color: $maincolor;
$link-color: $dark-blue;
$link-hover-color: $dark-blue;
$link-background-color: #ece6bc;

$border-color: #e7e7e1;
$border-color-focus: #979797;

$validation-color: red;

$transparent-bg: rgba(100, 100, 100, 0.2);
$arrow-icon-size: $extra-large-size;


//------------------------------------
//  RESPONSIVE
//------------------------------------

// Breakpoints
$breakpoint-map: (
  "extra-small": 30em, //480px
  "extra-small-max": 47.999em,
  "small":       48em, //ipad-min
  "small-max": 63.999em,
  "medium":      64em, //ipad-max
  "large":       80em, //1280px
  "extra-large": 100em,//1600 px
  "container-max": 115em, //1840px centered container
  "ipad": (min-device-width 768px) (max-device-width 1024px),
  "iphone": (min-device-width 320px) (max-device-width 480px),
  "iphone5": (min-device-width 320px) (max-device-width 568px)
);

// Selection colors
$selection-background:  #ece6bc;
$selection-color:       $maincolor !default;


// Form placeholder colors
$placeholder-color:     #bbbbbb !default;


// Colors used for our objects' borders, etc
$object-ui-color:       #329C42 !default;


// Dropdown colors
$dropdown-bg-color:     #fffa37 !default;
$dropdown-border-color: #ededed !default;
$dropdown-hover-color:  #f3f8f8 !default;
$dropdown-header-color: #aaaaaa !default;



// Button
$button-font-color: $maincolor !default;
$button-hover-font-color: $white;

//------------------------------------
// Spacing
//------------------------------------

$spacing-small: 5px;
$spacing-medium: 10px;
$spacing-xmedium: 15px;
$spacing-large: 40px;
$spacing-xlarge: 30px;
$spacing-xxlarge: 80px;
$spacing-giga: 120px;
$spacing-narrow: 220px;

$content-width: 1280px;

$max-width: 1818px;
$max-width-narrow: $content-width;
$max-width-extra-narrow: 840px;
$min-width: 320px;

$container-spacing: $spacing-large;
$container-spacing-desktop: $spacing-xxlarge;
$container-max-width: $max-width;

$container-spacing-narrow: $spacing-narrow;
$body-max-width: $max-width-narrow;
$block-indent-small: 30px;

//------------------------------------
//  Components
//------------------------------------

// Gutter size
$gutters: 2% !default;


// Prefix for grid columns. By default columns
// are formatted as `.column-8`
$column-prefix: 'column-' !default;


// Dictate how many columns you would like your
// grid system to use. Default is 12.
$column-number: 16 !default;

$use-grid:           true !default;
$use-push:	       true !default;
$use-pull:	       true !default;
