@import "../../scss/shared-components-styles/mixed-content-container";
$main-column-width: 42%;

.factbox-with-text-block {
  $self: &;
  @include mixed-content-block;

  &__container {
    @include mixed-content-container;

    &_reversed {
      @include mixed-content-container-reversed;
    }
  }

  &__column {
    @include mixed-content-container-col(false, $main-column-width);

    #{$self}__container_reversed & {
      @include mixed-content-container-col(true, $main-column-width);
    }
  }
}
