.main-menu {
  @extend .container;
  color: #fff;
  padding-top: 60px;
  position: relative;

  &__body {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  &__footer {
    margin-top: 135px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: $white;

    @include breakpoint(medium) {
      flex-direction: row;
      margin-top: 125px;
    }
  }

  &__items,
  &__third-column {
    flex: 1;
    list-style: none;
  }

  &__items {
    margin-right: 40px;

    &_expandable {
      &[aria-expanded="false"]{
        display: none;
      }
      @include breakpoint(medium) {
        &[aria-expanded="false"]{
          display: block;
        }
      }
    }
  }

  &__link {
    margin: 0;
    width: 100%;
    padding: 6px 0;

    @include breakpoint(medium) {
      padding: 12px 0;
    }
  }

  &__link_small {
    padding: 6px 0;
  }

  &__heading {
    $self: &;
    color: $white;
    position: relative;
    @include font-size(22px);
    font-weight: 600;
    margin: 0;
    padding: 12px 0px;
    display: flex;
    align-items: center;

    @include breakpoint(medium) {
      pointer-events: none;
      @include font-size(32px);
    }

    &-icon {
      margin: 0 15px;
      transform-origin: center center;
      transition: color 0.35s, -webkit-transform 0.35s;

      @include breakpoint(medium) {
        display: none;
      }
    }

    &_closed {
      #{$self}-icon {
        transform: rotate(90deg);
      }

    }

    &_open {
      #{$self}-icon {
        transform: rotate(-90deg);
      }
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
  }

  &__subscribe-link {
    margin-top: 15px;
  }
}

.main-menu-social {
  margin-top: 15px;
  margin-bottom: 15px;

  &__list {
    list-style: none;
    display: flex;
  }

  @include breakpoint(medium) {
    margin-right: 0;
    margin-top: 0;
    align-self: flex-start;
    flex: 0 0 calc( 100%/3 - 15px);
  }
}
