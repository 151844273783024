.article-page {
  @extend .page__container;

  &__heading {
    @extend .page__heading;
  }

  &__media {
    &_mobile {
      margin-bottom: 18px;
      margin-top: 40px;
      @include breakpoint(medium) {
        display: none;
      }
    }
    &_desktop {
      display: none;
      padding-top: 45px;
      @include breakpoint(medium) {
        display: block;
      }
    }
  }

  &__body {
    @extend .page__body;
    max-width: $body-max-width;
  }
}
