$toggle-icon-size: 21px;
$toggle-icon-size-mobile: 14px;

.accordion {
    &__item {
        border-bottom: 2px solid $dark-blue;

        &:first-child {
            border-top: 2px solid $dark-blue;
        }
    }

    &__heading {
        position: relative;
    }

    &__button {
        padding: 9px 0 10px;
        cursor: pointer;

        @include breakpoint(medium) {
            padding: 14px 0 16px;
        }

        &[aria-expanded=true] {
            &:before {
                transform: rotate(45deg) translateY(-47%);
                transform-origin: top;
            }
            &:after {
                transform: rotate(45deg);
            }
        }

        &:before {
            position: absolute;
            top: 50%;
            right: 6px;
            margin-top: 1px;
            content: ' ';
            height: $toggle-icon-size-mobile;
            transform: translateY(-50%);
            width: 3px;
            background-color: $dark-blue;

            @include breakpoint(medium) {
                height: $toggle-icon-size;
                right: 30px;
            }
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 0;
            content: ' ';
            height: 3px;
            width: $toggle-icon-size-mobile;
            background-color: $dark-blue;

            @include breakpoint(medium) {
                width: $toggle-icon-size;
                right: 21px;
            }
        }
    }

    &__panel {
        padding: 10px 0 15px;
        @include breakpoint(medium) {
            padding: 10px 0 18px;
        }
    }
}