.pages-list {
  $self: &;

  &__items {
    list-style: none;
    padding-top: 20px;
  }

  &__filter{
    position: relative;
  }
}

.pages-list-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  color: $dark-blue;
  @include breakpoint(medium) {
    flex-direction: row;
  }

  &__image {
    @include fixed-proportion-image(404, 228);
    .pages-list-item__link-holder:focus &,
    .pages-list-item__link-holder:hover & {
      .image__img {
        transform: scale(1.1);
      }
    }
  }

  &__row {
    flex: 0 0 auto;
    width: 100%;
    @include breakpoint(medium) {
      flex: 1 1 auto;
      margin-right: 45px;
    }
  }

  &__heading {
    margin-top: 30px;
    margin-bottom: 12px;
    @include font-size(32px);
    font-weight: 600;
    @include breakpoint(medium) {
      margin-top: 0px;
    }
  }

  &__link-holder {
    text-decoration: none;
  }

  &__link {
    display: block;
    margin-top: 20px;
    font-weight: 500;
    text-decoration: underline;
    transition: color 0.35s, -webkit-transform 0.35s;

    .pages-list-item__link-holder:hover & {
      color: $blue;
      text-decoration: none;
    }
  }
}
