
.spinner {
    @include full-size;
    z-index: 10;
    background: rgba(white, 0.5);


&__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
}

  &__icon {
    font-size: 15px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0) scale(0.5);
    -ms-transform: translateZ(0) scale(0.5);
    transform: translateZ(0) scale(0.5);

  }
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $extra-dark-blue, 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.5), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.7), 1.8em -1.8em 0 0em $extra-dark-blue,
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.5), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.7),
        2.5em 0em 0 0em $extra-dark-blue, 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.5),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.7), 1.75em 1.75em 0 0em $extra-dark-blue,
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.5), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.7),
        0em 2.5em 0 0em $extra-dark-blue, -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.5),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.7), -1.8em 1.8em 0 0em $extra-dark-blue,
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.5), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.7),
        -2.6em 0em 0 0em $extra-dark-blue, -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.5),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.7), -1.8em -1.8em 0 0em $extra-dark-blue;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em $extra-dark-blue, 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.5), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.7), 1.8em -1.8em 0 0em $extra-dark-blue,
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.5), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.7),
        2.5em 0em 0 0em $extra-dark-blue, 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.5),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.7), 1.75em 1.75em 0 0em $extra-dark-blue,
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.5), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.7),
        0em 2.5em 0 0em $extra-dark-blue, -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.2),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.5),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.7), -1.8em 1.8em 0 0em $extra-dark-blue,
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.2), -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.5), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.7),
        -2.6em 0em 0 0em $extra-dark-blue, -1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba($extra-dark-blue, 0.2), 1.8em -1.8em 0 0em rgba($extra-dark-blue, 0.2),
        2.5em 0em 0 0em rgba($extra-dark-blue, 0.2), 1.75em 1.75em 0 0em rgba($extra-dark-blue, 0.2),
        0em 2.5em 0 0em rgba($extra-dark-blue, 0.2), -1.8em 1.8em 0 0em rgba($extra-dark-blue, 0.5),
        -2.6em 0em 0 0em rgba($extra-dark-blue, 0.7), -1.8em -1.8em 0 0em $extra-dark-blue;
    }
  }
}
