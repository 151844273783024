@use "sass:math";

$getting-started-desktop-spacing: math.div(120px, 3);
$getting-started-mobile-spacing: 26px;
$getting-started-desktop-width: calc(100% / 4 - 20px);
$getting-started-mobile-width: calc((100% - #{$getting-started-mobile-spacing}) / 2);
$heading-desktop-width-spacing: $getting-started-desktop-spacing;
$heading-desktop-width: calc(100% / 2 - 20px);


.front-page-hero {
  color: white;
  line-height: 1.2;

  &__image-with-heading {
    position: relative;
    min-height: 460px;
    display: flex;
    align-items: flex-end;

    @include breakpoint(medium) {
      min-height: 100vh;
    }
  }

  &__heading-container {
    position: relative;
    padding: 180px 30px 50px;
    margin: auto;

    @include breakpoint(medium) {
      padding-top: 140px;
      padding-bottom: 210px;
      max-width: 1060px;
    }
  }

  &__image {
    overflow: hidden;
  }

  &__heading {
    font-weight: 500;
    color: white;
    line-height: 1.25;
    text-align: center;
    text-shadow: 0px 0px 14px #00000066;
    margin: 0px;
    @include responsive-font-size(36px, 68px);
  }

  &__wave {
    @include fixed-proportion(480, 60);
    background-image: url('../../assets/images/wave.svg');
    background-position: center calc(100% + 2px);
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    bottom: 0;

    @include breakpoint(small) {
      @include fixed-proportion(1440, 190);
    }
  }

  &__background {
    width: 100%;
    position: relative;
    background: transparent linear-gradient(90deg, $blue 0%, #00558D 100%) 0% 0% no-repeat;
  }

  &__content {
    @include container();

    & {
      padding-top: 40px;
      padding-bottom: 45px;
      margin: auto;
    }

    @include breakpoint(medium) {
      padding-bottom: 97px;
    }
  }

  &__secondary-headings {
    display: block;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint(small) {
      display: flex;
    }
  }

  &__left-heading {
    @include font-size(36px);
    line-height: inherit;
    width: 100%;
    margin: 0;
    font-weight: 500;
    flex: 1;

    @include breakpoint(medium) {
      @include font-size(54px);
      width: $heading-desktop-width;
    }
  }

  &__right-heading {
    width: 100%;
    font-weight: 400;
    line-height: 1.34;
    flex: 1;
    margin: 26px 0 0 0;
    @include responsive-font-size(26px, 32px);

    @include breakpoint(medium) {
      width: $heading-desktop-width;
      margin: 0 0 0 $heading-desktop-width-spacing;
    }
  }

  &__getting-started {
    display: none;
    list-style: none;
    margin-top: 0;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      padding-top: 40px;
      flex-wrap: nowrap;
      display: flex;
    }
  }

  &__getting-started-item {
    padding-bottom: 36px;
    position: relative;
    width: $getting-started-mobile-width;

    @include breakpoint(extra-small-max, $min-max: "max") {
      width: 100%;
      margin-left: 0;
    }

    @include breakpoint(small-max, $min-max: "max") {
      &:nth-child(odd) {
        margin-left: 0;
      }
    }

    @include breakpoint(medium) {
      width: $getting-started-desktop-width;
      margin-left: $getting-started-desktop-spacing;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__getting-started-item-heading {
    margin-bottom: 5px;
    @include font-size(24px);
  }

  &__getting-started-item-intro {
    @include font-size(20px);
    line-height: normal;
  }

  &__getting-started-item-link {
    color: $white;
    position: absolute;
    text-decoration: none;
    bottom: 0;
    @include font-size(20px);
    &:hover {
      color: $light-blue;
    }
  }

  &__reed-more-link {
    margin-top: 30px;
    display: inline-block;
    font-weight: 400;
    text-decoration: none;
    @include breakpoint(medium) {
      display: none;
    }
  }
}
