.see-also-links {

  &__heading {
    @include font-size(32px);
    font-weight: 600;
  }

  &__items {
    list-style: none;
  }

  &__item {
    list-style: none;
  }

  &__link {
    @include font-size(18px);
    display: inline-flex;
    color: $dark-blue;
    text-transform: uppercase;
    padding: 16px 20px;
    border: 2px solid $dark-blue;
    text-decoration: none;
    font-weight: 600;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    justify-content: space-between;

    &:hover {
      background: $dark-blue;
      color: $white;
      transition: all 0.35s, -webkit-transform 0.35s;
    }
  }
}
