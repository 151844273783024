.getting-started-teaser {
  $self: &;
  text-decoration: none;
  color: $dark-blue;

  &__heading {
    @include font-size(24px);
    margin-top: 10px;
    margin-bottom: 0;
    @include breakpoint(medium) {
      @include font-size(32px);
      margin-top: 30px;
    }
  }

  &__more-link {
    display: block;
    margin-top: 20px;
    font-weight: 500;
    text-decoration: underline;

    #{$self}:focus &,
    #{$self}:hover & {
      text-decoration: none;
      color: $blue;
    }
  }

  &__image {
    @include fixed-proportion-image(400, 225);

    #{$self}:focus &,
    #{$self}:hover & {
      .image__img {
        transform: scale(1.1);
      }
    }
  }
}
