.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  background-color: gray;
  margin-top: $block-indent-small;
  margin-bottom: $block-indent-small;
  user-select: none;

  @include breakpoint(medium) {
    margin-bottom: 95px;
    margin-top: 95px;
  }

  &__overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all 0.3s;
    background: none;
    border: none;

    &:hover, &:focus-visible {
      opacity: 0.7;
    }

    svg {
      width: 70px;
      height: 70px;

      @include breakpoint(medium) {
        width: 140px;
        height: 140px;
      }
    }
  }

  &__iframe {
    &-holder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s, opacity 0.3s linear;

      &_visible {
          opacity: 1;
          visibility: visible;
      }
    }
    
    &-container {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
      
      iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
    }
  }

  video {
    width: 100%;
  }
}