.accordion-block {
    @include container($max-width-extra-narrow);
    @include reset-container-inside-content-area;
    @include reset-container-inside-rich-text;

    & {
        box-sizing: content-box;
        margin-bottom: $block-indent-small;
        margin-top: $block-indent-small;
    }

	@include breakpoint(medium) {
		margin-bottom: 95px;
		margin-top: 95px;
	}

    &__container {
        position: relative;
    }

    &__title {
        &, 
        .rich-text & {
            display: block;
            font-weight: 600;
            @include font-size(28px);
            margin: 0 0 20px;
    
            @include breakpoint(medium) {
                @include font-size(32px);
                margin: 0 0 30px;
            }
        }
    }

    &__item {
        &-heading {
            @include font-size(20px);
            line-height: normal;
            font-weight: 500;
        }

        &-content {
            margin-bottom: -10px;
        
            @include breakpoint(medium) {
                padding-top: 15px;
            }
        }
    }

    &__text {
        @include font-size(20px);
        line-height: normal;
        font-weight: 400;
    }
}