.header {
  display: flex;
  @extend .container;
  padding-top: 33px;
  padding-bottom: 0;
  top: 0;

  @include breakpoint(medium) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__logo {
    width: 182px;
    position: relative;
    flex: 0 0 auto;

    @include breakpoint(medium) {
      width: 220px;
    }
  }

  &__button {
    border: none;
    background: none;
    cursor: pointer;
    color: inherit;
    position: relative;
    padding: 0 0 0 15px;
    margin-left: 20px;
    align-self: center;
  }

  &__toolbar {
    flex: auto;
    display: flex;
    justify-content: flex-end;

    @media print {
      display: none;
    }
  }

  &__popup {
    visibility: hidden;
    overflow: auto;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;

    &::before {
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      display: block;
      background: $dark-blue;
      opacity: 0.95;
    }

    &_opened {
      display: flex;
      flex-direction: column;
      visibility: visible;
      opacity: 1;
      z-index: 100;
    }
  }

  &__popup-header {
    @extend .container;
    padding-top: 33px;
    display: flex;
    justify-content: space-between;
    color: #fff;

    @include breakpoint(medium) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  
    &:before,
    &:after {
      display: none;
    }
  }

  &__popup-logo {
    position: relative;
    width: 182px;
    @include breakpoint(medium) {
      width: 220px;
    }
  }
}

.header-menu {
  display: none;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  @include breakpoint(medium) {
    display: flex;
  }

  @media print {
    display: none;
  }

  &__items {
    display: flex;
    list-style: none;
  }

  &__item {
    @include font-size(18px);
  }

  &__link {
    padding: 5px;
    margin-left: 20px;
  }
}
