.course-content {
  padding-top: 30px;

  &__loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 10;
  }

  &__error{
    @extend .page__container;
    color: red;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__navigation {
    display: flex;
    position: relative;
    @extend .page__container;

    &-items {
      list-style: none;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow: hidden;

      @include breakpoint(small) {
        padding-right: 30px;
      }
    }

    &-item {
      color: $orange;
      border-color: $orange;
      border-style: solid;
      border-width: 2px;
      border-bottom-width: 0;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      flex: 0 0 auto;
      width: 100%;

      &:last-child {
        border-bottom-width: 2px;
      }

      @include breakpoint(small) {
        border-right-width: 0;
        border-bottom-width: 2px;
        margin-bottom: 10px;
        width: auto;
        max-width: calc(100% - 30px);
      }

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    &-item-button {
      background: none;
      border: none;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      margin: 0;
      padding: 15px 35px 15px 45px;
      position: relative;
      cursor: pointer;
      text-transform: uppercase;
      display: block;
      max-width: 100%;
      width: 100%;

      @include breakpoint(small) {
        width: auto;

        &:after,
        &:before {
          content: "";
          position: absolute;
          border: 0 solid $orange;
          border-width: 31px 20px;
          width: 0;
          height: 0;
        }

        &:after {
          left: 100%;
          border-color: #0000;
          border-left-color: $orange;
          top: -2px;
        }

        &:before {
          top: 0;
          left: calc(100% - 1px);
          border-color: #0000;
          border-left-color: #fff;
          z-index: 2;
          border-width: 29px 19px;
          top: 0;
        }
      }

      &-text{
        display: block;
        font-weight: 600;

        @include breakpoint(small) {
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &:focus,
      &_active {
        background: $orange;
        color: $white;

        &:before {
          border-left-color: $orange;
        }
      }
    }

    &-buttons{
      @include container;

      & {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }

      @include breakpoint(medium) {
        margin-top: 40px;
      }
    }

    &-button{
        appearance: none;
        border: none;
        font-family: inherit;
        background-color: white;
        border: 2px solid $dark-blue;
        color: $dark-blue;
        @include font-size(20px);
        font-weight: bold;
        text-transform: uppercase;
        padding-top: 18px;
        padding-bottom: 18px;
        cursor: pointer;
        position: relative;
        flex: 0 0 180px;
        display: block;
        max-width: calc(50% - 6px);
        overflow: hidden;

        &:focus,
        &:hover{
            color: white;
            background-color: $dark-blue;
        }

        &:focus {
            .mouse-user & {
                background-color: white;
                color: $dark-blue;
                &:hover {
                  color: white;
                  background-color: $dark-blue;
              }
            }
        }
        &:before{
          content: '';
          display: block;
          border: 4px solid currentColor;
          border-bottom: none;
          border-left: none;
          top: 50%;
          margin-top: -9px;
          width: 18px;
          height: 18px;
          transform-origin: center center;
          position: absolute;
        }

        &--prev{
          margin-right: auto;
          padding-left: 40px;
          padding-right: 18px;
          text-align: right;

          &:before{
            left: 17px;
            transform: rotate(-135deg);
          }
        }

        &--next{
          margin-left: auto;
          padding-right: 40px;
          padding-left: 18px;
          text-align: left;

          &:before{
            right: 17px;
            transform: rotate(45deg);
          }
        }
    
    }
  }
}
