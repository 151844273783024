.tag-filter {
    margin: 0 0 $spacing-xlarge;

    &__list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        &-item{
            padding: 0 $spacing-medium $spacing-medium 0;
        }
    }
}
