.modal {
	overflow-y: auto;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1;
	left: 0;
	top: 0;
	color: $white;
	text-align: center;
	background: rgba($dark-blue, 0.95);

	&::before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}

	&__container {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		max-width: 880px;
		width: 100%;
		padding: 30px 20px;
		text-align: left;
	}

	&__logo {
		max-width: 110px;
	}

	&__close-button {
		display: flex;
		align-items: center;
		margin-left: auto;
		border: none;
		background: none;
		cursor: pointer;
		color: $white;
		position: relative;

		&-label {
			margin-right: 15px;
			margin-top: 2px;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: $font-weight-bold;
		}

		svg {
			width: 28px;
			height: 28px;
		}
	}

	.rich-text {
		margin-top: 25px;
		color: inherit;
	}
}