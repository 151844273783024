.getting-started-page {
  @extend .page__container;

  &__heading {
    @extend .page__heading;
  }

  &__intro{
    margin-bottom: $spacing-xlarge;
  }

  &__body {
    @extend .page__body;
  }

  &__content-group {
    display: flex;
    flex-direction: column;
    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  &__sidebar {
    min-width: 400px;

    @include breakpoint(medium) {
      padding-top: 110px;
    }
  }

  &__pages-list {
    width: 100%;
  }
}
