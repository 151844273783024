$simple-teaser-desktop-spacing: 80px * 0.5;
$simple-teaser-mobile-spacing: 20px;
$simple-teaser-desktop-width: calc(100% / 2 - 20px);
$simple-teaser-mobile-width: calc((100% - #{$simple-teaser-mobile-spacing}));

.getting-started-list {
  margin-top: 30px;

  &__items {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__item {
    list-style: none;
    width: $simple-teaser-mobile-width;
    margin-left: $simple-teaser-mobile-spacing;
    margin-bottom: 45px;

    &:nth-child(odd) {
      margin-left: 0;
    }

    @include breakpoint(extra-small-max, $min-max: "max") {
      width: 100%;
      margin-left: 0;
    }

    @include breakpoint(medium) {
      width: $simple-teaser-desktop-width;
      margin-left: $simple-teaser-desktop-spacing;
    }
  }
}
