//------------------------------------
//  GRID
//------------------------------------
.row {
  display: block;
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}
[class*='#{$column-prefix}'] {
  background-clip: padding-box !important;
  &.right {
    float: right;
  }
}
[class*='#{$column-prefix}'] {
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}
@include breakpoint(medium) {
  [class*='#{$column-prefix}'] {
    float: left;
  }
  .gutters [class*='#{$column-prefix}'] {
    margin-left: $gutters;
    &:first-child {
      margin-left: 0;
    }
  }
  .gutters-off [class*='#{$column-prefix}'] {
    margin-left: 0;
  }
  // Row columns
  @include grid-setup();
  // Push
  // Thanks to inuitcss (https://github.com/csswizardry/inuit.css) for the idea to
  // abstract 'push' and 'pull' into their own mixins. This makes adding/removing
  // them to projects absolutely painless.
  @if $use-push==true {
    [class*='push-'] {
      position: relative;
    }
    @include push-pull-setup(push, $column-number);
  }
  // Pull
  @if $use-pull==true {
    [class*='pull-'] {
      position: relative;
    }
    @include push-pull-setup(pull, $column-number);
  }
}
// Non-responsive grid
.non-responsive {
  [class*='#{$column-prefix}'] {
    float: left;
    width: auto !important;
  }
  .gutters [class*='#{$column-prefix}'],
  &.gutters [class*='#{$column-prefix}'] {
    margin-left: $gutters;
  }
  .gutters [class*='#{$column-prefix}']:first-child,
  &.gutters [class*='#{$column-prefix}']:first-child {
    margin-left: 0;
  }
}
*[class^="column-"] {
  padding-left: $spacing-large;
  padding-right: $spacing-large;
  
  @include breakpoint(medium) {
    min-height: 1px;
  }
}
.row {
  margin-left: -$spacing-large;
  margin-right: -$spacing-large;
}