.full-width-banner {
  $self: &;
  position: relative;
  padding-bottom: 30px;

  @include breakpoint(medium) {
    padding: 0;
    max-width: unset;
  }

  &_style_overlay &__image{
    &:after {
      content: "";
      display: block;
      @include full-size;
      background: rgba(0, 0, 0, 0.25);
      z-index: 2;
    }
  }

  &__content {
    color: $dark-blue;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;

    @include breakpoint(medium) {
      @include full-size;
      z-index: 3;
      color: $white;
      padding-bottom: 75px;
      padding-left: initial;
      padding-right: initial;
      max-width: 825px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &__heading {
    @include font-size(28px);
    @include breakpoint(medium) {
      @include font-size(54px);
      margin-bottom: 27px;
    }
  }

  &__text {
    font-weight: 400;
    @include breakpoint(medium) {
      @include font-size(24px);
      line-height: 1.35;
      text-align: center;
    }
  }

  &__more-link {
    display: inline-block;
    color: inherit;
    text-transform: uppercase;
    padding: 14px 48px;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    text-decoration: none;
    font-weight: 500;
    margin-top: 25px;

    @include breakpoint(medium) {
      margin-top: 45px;
    }

    &:hover {
      background: $dark-blue;
      color: $white;
      border-color: $dark-blue;
      transition: color 0.35s, -webkit-transform 0.35s;

      @include breakpoint(medium) {
        background: $white;
        color: $dark-blue;
        border-color: $white;
        transition: color 0.35s, -webkit-transform 0.35s;
      }
    }
  }
}
