.link-button {
  border-width: 2px;
  border-style: solid;
  border-color: inherit;

  &_theme_subscribe{
    @include font-size(24px);
    line-height: 1;
    border-color: currentColor;
    color: white;
    text-align: center;
    padding: 16px 20px;
    text-decoration: none;
    font-weight: 500;
    display: inline-flex;
    justify-content: center;
    
    @include breakpoint(medium){
      width: 100%;
      max-width: 400px;
    }

    &:hover {
      background: $white;
      color: $dark-blue;
      transition: all 0.35s, -webkit-transform 0.35s;
    }
  }
}
