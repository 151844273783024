.error-block { 
    @extend .container;
    text-align: center;
    color: $dark-blue;
    padding-bottom: $spacing-xxlarge;
    margin-top: 225px;

    @include breakpoint(small) {
        margin-top: 300px;
    }

    &__title {
        margin: $spacing-xlarge 0;
        @include responsive-font-size(154px, 284px);

        @include breakpoint(small) {
            margin: $spacing-xxlarge 0;
        }
    }

    &__big-text {
        padding-top: $spacing-xlarge;
        font-size: 32px;
        margin-bottom: $spacing-small;
    }

    &__small-text {
        padding-bottom: 50px;
        margin-top: $spacing-medium;
    }

    &__image-container {
        position: absolute;
        width: 60px;
        right: 20%;
        bottom: -30px; 
        
        @include breakpoint(medium) {
            width: 115px;
            bottom: -100px;
        }
    }


    &__link-container {
        margin-top: $spacing-xlarge;
    }

    &__link {
        color: $dark-blue;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        padding: 20px 40px 15px;

        &:hover {
            background-color: $dark-blue;
            color: $white;
        }
    }
}