.image-gallery {
	margin: $block-indent-small auto;
	overflow: hidden;

	@include stretch-block-inside-content-area;
	@include stretch-block-inside-page-body;
	
	@include breakpoint(medium) {
		margin-bottom: 80px;
		margin-top: 80px;
	}

	&__container {
		@include container($max-width-extra-narrow);
		@include reset-container-inside-content-area;
		@include reset-container-inside-rich-text;
	}

	.slick-slide {
		margin: 0 20px;
		float: none;
	}

	.slick-list {
		overflow: visible;
		margin: 0 -20px;
	}

	.slick-track {
		display: flex;
	}

	.slick-arrow {
		display: none;
		position: absolute;
		z-index: 1;
		font-size: 0;
		background: none;
		border: none;
		cursor: pointer;
		padding: 5px;
		transform: translateY(-50%);

		&:hover, 
		&:focus {
			opacity: 0.5;
		}

		&:before {
			content: '';
			border: solid #fff;
			border-width: 0 5px 5px 0;
			display: inline-block;
			padding: 12px;
		}

		&.slick-prev {
			left: 20px;
			&:before {
				transform: rotate(135deg);
			}
		}

		&.slick-next {
			right: 20px;
			&:before {
				transform: rotate(-45deg);
			}
		}

		&.slick-disabled {
			display: none!important;
		}
	}

	.slick-dots {
		display: none;
		position: absolute;
		left: 50%;
		text-align: center;
		padding-top: 10px;
		padding-left: 0;
		transform: translateX(-50%);
		
		li {
			display: inline-block;
			margin: 0 5px;

			button {
				width: 15px;
				height: 15px;
				padding: 0;
				border-radius: 50%;
				background: rgba($white, 0.5);
				border: 2px solid $white;
				font-size: 0;
				box-shadow: 1px 1px 2px 1px rgba($grey, 0.3);
				cursor: pointer;
			}

			&.slick-active {
				button {
					background: $white;
					box-shadow: 2px 2px 3px 1px rgba($grey, 0.3);
				}
			}
		}
	}
}