.image-with-caption {

  &__caption {
    display: block;
    color: inherit;
    @include font-size(14px);
    line-height: 1.3;
    font-weight: 400;
    margin-top: 8px;

    @include breakpoint(medium) {
      @include font-size(16px);
      line-height: 1.35;
      margin-top: 15px;
    }
  }
}
