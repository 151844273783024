.image {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  &_fluid {
    @include fluid-image();
  }

  &__img {
    display: block;
    width: 100%;
  }

  &_size {
    &_full-width-banner {
      @include fixed-proportion-image(1280, 674);
    }
    &_image-with-caption,
    &_simple-list-teaser {
      @include fixed-proportion-image(1280, 720);
    }
    &_factbox {
      @include original-proportions-image(72, 60);
    }
    &_image-gallery {
      @include fixed-proportion-image(840, 570);
    }
  }
}
