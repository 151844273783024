.search {
    &__title {
        margin-top: 24px;
        margin-bottom: 20px;
        font-size: 36px;
        font-weight: 500;
        line-height: 1;

        @include breakpoint(small) {
            font-size: 54px;
            margin-top: $spacing-xxlarge;
            margin-bottom: 25px;
        }
    }
    &__form{
        position: relative;
    }

    &-form {
        width: 100%;

        &__filters-container {
            display: block;
            margin: 0 0 40px;

            @include breakpoint(small){
                display: flex;
                margin: 0 0 10px;
                justify-content: stretch;
            }
            
        }

        &__column{
            width: 100%;
            margin-bottom: 10px;

            @include breakpoint(small){
                flex: 1;
                margin-right: 40px;
                margin-bottom: 30px;

                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }

    &-query-input {
        display: flex;
        width: 100%;
        border: solid 2px $dark-blue;

        &__input {
            width: 100%;
            color: $dark-blue;
            padding: 15px;
            border: none;
            font-family: $font-family;
            font-size: 20px;
            font-weight: 500;
        }

        &__icon-submit-button {
            background: none;
            border: 0;
          }
        
          &__search-icon {
            color: $dark-blue;
            margin-right: 10px;
          }
    }
}