.quick-search-panel {
  @extend .container;
  padding-bottom: 30px;
  position: relative;
  flex: 1;

  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: 840px;
    max-width: 840px;
    margin: auto;
  }

  &__container {
    display: flex;
    width: 100%;
    .--blue-with-icon & {
       border: solid 2px $dark-blue;
    }

  }

  &__input {
    width: 100%;
    background: #0000;
    border: solid 2px #fff;
    padding: 10px 15px;
    color: $white;
    @include font-size(20px);

    &::placeholder {
      color: inherit;
    }

    @include breakpoint(medium) {
      padding: 34px;
      @include font-size(35px);
    }

    .--blue-with-icon & {
      color: $dark-blue;
      padding: 15px;
      border: none;
    }
  }

  &__icon-submit-button {
    background: none;
    border: 0;
  }

  &__search-icon {
    color: $dark-blue;
    margin-right: 10px;
  }

  &__submit {
    cursor: pointer;
    margin-top: 100px;
    align-self: flex-end;
    color: white;
    background: none;
    border: 0;
    @include font-size(32px);
    border-bottom: solid 2px $white;
    padding: 0;
    &:hover {
      text-decoration: none;
    }
  }

  &__explanation {
    display: none;
    margin-top: 34px;
    @include breakpoint(medium) {
      display: block;
    }
  }
}

