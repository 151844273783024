.print-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  
  &__text {
    padding-left: 15px;
    color: $dark-blue;
    font-family: $font-family;
    font-weight: 500;
    @include font-size(20px);
  }

  &__icon {
    width: 30px;
  }

  @media print {
    display: none;
  }
}
