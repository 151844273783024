.select-option {    
    &__label {
        position: relative;
        color: $orange;
        font-size: 20px;
        font-weight: 500;
        font-family: $font-family;
        border: 2px solid $orange;
        border-radius: 20px;
        padding: $spacing-small $spacing-medium;
        display: block;
        background-color: $white;
    }

    &__label:hover,
    &__label--checked {
        color: white;
        background-color: $orange;
    }

    html:not(.mouse-user) &__label:focus {
        background: $blue;
        color: $white;
    }
}
