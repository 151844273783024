.menu-link {
  color: inherit;
  display: block;
  font-weight: 600;
  text-decoration: none;
  @include font-size(22px);

  @include breakpoint(medium) {
    @include font-size(32px);
  }

  &:hover,
  &_active {
    color: $blue;
    text-decoration: none;
  }

  &_small {
    font-weight: 400;
    text-decoration: none;

    @include font-size(18px);
    @include breakpoint(medium) {
      @include font-size(20px);
    }
  }

  &_big {
    font-size: inherit;
    font-weight: 500;
    text-transform: uppercase;
    &:hover,
    &_active {
      color: $orange;
    }
  }
}
