.drop-down {
  position: relative;
  margin-bottom: 30px;

  &__header {
    min-width: 115px;
    padding: 6px 20px;
    font-weight: 500;
    border: solid 2px $dark-blue;
    display: inline-block;
    text-align: center;
    position: relative;
    background: none;
    color: $dark-blue;
    font-family: $font-family;
    font-size: 1em;
    line-height: $line-height;
    cursor: pointer;

    &_with_arrow {
      padding-right: 50px;

      &:before {
        display: block;
        content: '';
        height: 100%;
        width: 2px;
        top: 0;
        right: 40px;
        background: $dark-blue;
        position: absolute;
      }

      &:after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 12px;
        border-top: solid 12px $dark-blue;
        border-left: solid 7px transparent;
        border-right: solid 7px transparent;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
      }
    }

    &:focus{
      background: $dark-blue;
      color: $white;

      &:after{
        border-top-color: $white;
      }
    }
  }

  &__options {
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    padding: 0;
    margin: 0;
    background: $white;
    border: solid 2px $dark-blue;
    box-sizing: border-box;
    font-weight: 500;
    min-width: 115px;
    display: none;

    &_opened{
      display: block;
    }
  }

  &__label {
    @include font-size(24px);
    font-weight: 600;
    padding-left: 10px;
  }

  &__option {
    list-style: none;
    text-align: center;   
    padding: 0;

    &-button{
      display: block;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-family: inherit;
      color: inherit;
      background: none;
      padding: 10px 30px;
      border: none;
      width: 100%;
      cursor: pointer;
      
      &:hover,
      &:focus {
        background: $dark-blue;
        color: $white;
      }
    }
  }
}
