.search-result {
    overflow: hidden;

    &__items {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        width: calc(100% + 40px);
        margin-right: -40px;
    }

    &__item {
        padding-right: 40px;
        padding-bottom: $spacing-xlarge;
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);

        @include breakpoint(extra-small-max, $min-max: "max") {
            width: 100%;
            margin-right: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &__button-container {
        width: 100%;
        padding: $spacing-xlarge 0;
        display: flex;
        justify-content: center;
        position: relative;
    }

    &__button {
        width: 100%;
        appearance: none;
        border: none;
        font-family: inherit;
        background-color: white;
        border: 2px solid $dark-blue;
        color: $dark-blue;
        @include font-size(20px);
        font-weight: 500;
        text-transform: uppercase;
        padding: 20px 15px 15px;
        cursor: pointer;

        @include breakpoint(small) {
            width: auto;
        }

        &:focus,
        &:hover {
            color: white;
            background-color: $dark-blue;
        }

        &:focus {
            .mouse-user & {
                background-color: white;
                color: $dark-blue;
            }
        }
    }
}
