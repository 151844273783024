.footer {
    width: 100%;
    bottom: 0;
    color: white;
    font-size: 16px;
    font-weight: 400;

    &__wave {
      @include fixed-proportion(480, 60);
      background-image: url('../../assets/images/wave.svg');
      background-position: center calc(100% + 2px);
      background-size: 100% auto;
      background-repeat: no-repeat;
      width: 100%;
      bottom: 0;

      @include breakpoint(small) {
        @include fixed-proportion(1440, 190);
      }
    }

    &__background-container {
         background: transparent linear-gradient(90deg, $blue 0%, #00558D 100%) 0% 0% no-repeat;
    }

   

    &__content {
        @extend .container;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
       

        @include breakpoint(medium){
            flex-wrap: wrap-reverse;
            text-align: left;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: row;
        }
    }

    &__text, &__links {
         margin-bottom: $spacing-xlarge;
         width: 100%;
         @include breakpoint(medium) {
             width: 33.3%;
         }
    }

    &__text {
        order: 2;
        @include breakpoint(medium){
            order: 1;
        }
    }

    &__links {
        order: 1;
        display: flex;
        flex-direction: column;
        
        @include breakpoint(medium) {
            order: 2;
            width: 33.3%;
        }
    }

    &__subscribe-link{
        margin:0 auto 35px;

        @include breakpoint(medium){
            margin-left: 0;
        }
    }

    &__social-link-list {
        display: flex;
        list-style: none;
        justify-content: center;
        flex-direction: row;
        width: 100%;

        @include breakpoint(medium) {
            justify-content: flex-start;
            margin-left: -15px;
        }
    }
    

    &__copyright-row {
        background-color: $extra-dark-blue;
    }

    &__copyright-row-content { 
        @extend .container;
        padding-top: $spacing-medium;
        padding-bottom: $spacing-medium;
        text-align: center;
        margin:auto;
        @include breakpoint(medium) {
           display: flex; 
           text-align: left;
        }
    }

    &__copyright-row-text {
        width: 100%;
        @include breakpoint(medium) {
            width: 33.3%;
        }
        
    }

    &__copyright-row-link {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
