.factbox {
    padding: 20px;

    @include breakpoint(medium) {
        padding: 35px 30px;
    }

    &__icon {
        .image__img {
            margin: auto;
        }
   
        &-holder {
            display: flex;
            align-items: center;
            justify-content: center;
            float: left;
            width: 72px;
            min-height: 60px;
            margin-right: 15px;
            margin-bottom: 10px;
        }
    }

    &__title {
        &, 
        .rich-text & {
            display: flex;
            align-items: center;
            font-weight: 700;
            @include font-size(24px);
            line-height: 1.3;
            color: $black;
            min-height: 60px;
            margin: 0 0 10px;
            word-wrap: break-word;
    
            @include breakpoint(medium) {
                margin: 0 0 15px;
            }
        }
        
    }
}