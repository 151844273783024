$contact-image-size-desktop: 180px;
$contact-image-size-mobile: 160px;

.contact { 
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint(small) {
		flex-direction: row;
	}

	&__media-content {
		max-width: $contact-image-size-mobile;
		margin-bottom: 25px;

		@include breakpoint(small) {
			flex: 0 0 $contact-image-size-desktop;
			max-width: none;
			margin-right: 40px;
			margin-bottom: 0;
		}
	}

	&__main-content {
		flex: 1;
		@include font-size(20px);
		line-height: 1.3;
		text-align: center;
		word-wrap: break-word;

		@include breakpoint(small) {
		  text-align: left;
		}
	}

	&__name {
		margin: 0;
	}

	&__image {
		border-radius: 50%;
		width: 100%;
		height: $contact-image-size-mobile;
		@include object-fit(cover, center);

		@include breakpoint(small) {
			height: $contact-image-size-desktop;
		}
	}

	&__link {
		text-decoration: none;
	}
}